import * as React from "react";
import type { CalculatorSideBarType } from "../lib/types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { clsx, Icon, Link } from "@monemprunt/design-system";
import { Link as LinkComponent } from "gatsby";

export interface SidebarCardProps extends CalculatorSideBarType {
  onIconClicked: () => void;
  active?: boolean;
  link: string;
  expanded?: boolean;
  className?: string;
}

export const SidebarCard = ({
  onIconClicked,
  active = false,
  icon,
  title,
  className,
  link,
  expanded = false
}: SidebarCardProps) => {
  return (
    <li
      className={clsx(
        "flex items-center gap-2 cursor-pointer justify-center",
        "lg:justify-start lg:relative",
        "focus-within:ring-2 focus-within:ring-secondary-100",
        className,
        {
          "!justify-start": expanded,
          "bg-light-100 rounded-r-md": active,
          "bg-light-50": !active
        }
      )}
      onClick={onIconClicked}>
      {icon.localFile.childImageSharp ? (
        <GatsbyImage
          image={getImage(icon.localFile)}
          alt={``}
          objectFit={`contain`}
          objectPosition={`center`}
          className={clsx("h-10 md:h-12", "cursor-pointer m-4", {
            grayscale: !active
          })}
        />
      ) : (
        <img
          src={icon.localFile.publicURL}
          alt={``}
          className={clsx(
            "h-10 md:h-12",
            "object-center object-contain",
            "cursor-pointer m-4",
            {
              grayscale: !active
            }
          )}
        />
      )}

      <Link
        to={link}
        external={false}
        customLink={(props) => <LinkComponent {...props} />}
        className={clsx(
          "hidden p-5 text-sm items-center justify-between w-full gap-2",
          "lg:after:inset-0 lg:after:absolute",
          "lg:flex",
          {
            "!flex": expanded,
            "text-secondary-100 font-bold": active
          }
        )}>
        <span>{title}</span>

        <Icon
          icon={`arrow-right`}
          ariaLabel=""
          className={clsx("h-5 flex-shrink-0", {
            "text-gray-400": !active
          })}
        />
      </Link>
    </li>
  );
};
