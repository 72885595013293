import { Link } from 'gatsby';
import * as React from 'react';
// @ts-ignore
import ImageSvg from '../images/ctaimage.svg';

export interface CtaImageProps {
    className?: string;
}

const CtaImage: React.FC<CtaImageProps> = ({ className }) => (
    <a href={`/tunnelmobile/`}>
        <img src={ImageSvg} alt="Estimez votre projet" className={className} />
    </a>
);

export default CtaImage;
