import * as React from "react";
import { clsx, Sidebar } from "@monemprunt/design-system";
import { graphql, useStaticQuery } from "gatsby";
import { CalculatorSideBarType } from "../lib/types";
import { SidebarCard } from "./sidebar-card";

// @ts-ignore
import BalanceSVG from "../images/balance-sidebar.svg";

export type SideBarActiveItem =
  | "tunnel"
  | "mensualite"
  | "ptz"
  | "frais_notaire"
  | "meteo"
  | "capacite_emprunt";

export interface SidebarProps {
  active: SideBarActiveItem;
  onToggle: (open: boolean) => void;
  isOpen: boolean;
}

export const CalculatorSidebar = ({
  active,
  onToggle,
  isOpen,
}: SidebarProps) => {
  const sideBarItems: Record<
    SideBarActiveItem,
    { calculatorSideBar: CalculatorSideBarType }
  > = useStaticQuery(graphql`
    query Sidebars {
      tunnel: strapiPageCalculetteTunnel {
        id: strapiId
        calculatorSideBar: calculatorSidebar {
          title
          icon {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
      capacite_emprunt: strapiPageCalculetteCapaciteDEmprunt {
        id: strapiId
        calculatorSideBar {
          title
          icon {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
      frais_notaire: strapiPageCalculetteFraisDeNotaire {
        id: strapiId
        calculatorSideBar {
          title
          icon {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
      mensualite: strapiPageCalculetteMensualite {
        id: strapiId
        calculatorSideBar {
          title
          icon {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
      ptz: strapiPageCalculettePtz {
        id: strapiId
        calculatorSideBar {
          title
          icon {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
      meteo: strapiPageMeteoDesTaux {
        id: strapiId
        calculatorSideBar: calculatorSidebar {
          title
          icon {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  `);

  function getLinkFromItem(item: SideBarActiveItem): string {
    switch (item) {
      case "tunnel":
        return "/";
      case "mensualite":
        return "/calcul-mensualites-credit-immobilier/";
      case "ptz":
        return "/calculer-pret-taux-zero/";
      case "frais_notaire":
        return "/simulation-frais-notaire/";
      case "capacite_emprunt":
        return "/calcul-capacite-emprunt/";
      case "meteo":
        return "/meteo-des-taux/";
    }
  }

  // Get all the sidebars for the current active tab
  return (
    <div
      className={clsx(
        "h-full bg-light-50 pb-52",
        "md:pb-44",
        "lg:col-span-3 lg:pt-0 lg:bg-transparent",
        "xl:col-span-3",
        {
          "col-span-10 pt-15 md:pt-20": isOpen,
          "col-span-2 pt-5 md:pt-14": !isOpen,
        },
      )}>
      <Sidebar
        onClose={() => onToggle(false)}
        className={clsx(
          "w-full h-[94vh] relative pt-2",
          "md:top-32",
          "lg:sticky lg:!bg-transparent lg:top-30",
          {
            "sticky top-28": !isOpen,
          },
        )}
        open={isOpen}>
        {Object.entries(sideBarItems).map(
          (
            [key, { calculatorSideBar: item }]: [
              SideBarActiveItem,
              { calculatorSideBar: CalculatorSideBarType },
            ],
            index,
          ) => (
            <SidebarCard
              key={key}
              onIconClicked={() => onToggle(true)}
              expanded={isOpen}
              active={active === key}
              link={`/simulation-emprunt-immobilier${getLinkFromItem(key)}`}
              className={`${
                index === Object.entries(sideBarItems).length - 1 &&
                "rounded-b-md"
              }`}
              {...item}
            />
          ),
        )}

        <div
          className={`hidden absolute bottom-10 left-0 right-0 lg:block xl:bottom-12 2xl:bottom-5`}>
          <img
            src={BalanceSVG}
            alt="Balance"
            className={`object-left-bottom lg:h-32 object-contain w-full 2xl:h-52`}
          />
        </div>
      </Sidebar>
    </div>
  );
};
