import { Icon } from "./icon";
import { clsx } from "../lib/functions";
import { jsx, jsxs } from "react/jsx-runtime";
const Sidebar = ({
  className,
  children,
  onClose,
  open = false
}) => {
  return /* @__PURE__ */ jsx(
    "aside",
    {
      className: clsx(`mec-sidebar`, className, {
        open
      }),
      children: /* @__PURE__ */ jsxs("nav", { style: { zIndex: 7 }, children: [
        /* @__PURE__ */ jsx("div", { className: "mec-sidebar__header", children: /* @__PURE__ */ jsx(
          "button",
          {
            onClick: onClose,
            "aria-label": "fermer le menu",
            className: "mec-sidebar__header__btn-close",
            children: /* @__PURE__ */ jsx(Icon, { icon: "close", ariaLabel: "" })
          }
        ) }),
        /* @__PURE__ */ jsx("ul", { className: "mec-sidebar__list", children })
      ] })
    }
  );
};
export {
  Sidebar
};
