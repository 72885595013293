import { Link } from "./link";
import { clsx } from "../lib/functions";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
const elementTypes = [
  "div",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "li"
];
const HeadTitle = ({
  href,
  as,
  label,
  className = "",
  breakWords = false,
  thin = false,
  alignLabels = false,
  variant = "secondary",
  customLink = (props) => /* @__PURE__ */ jsx("a", { ...props, href: props.to })
}) => {
  let first = label;
  let second = null;
  if (breakWords) {
    [first, second] = breakWordInTwo(label);
  }
  const LabelComponent = /* @__PURE__ */ jsx("span", { className: `mec-head-title__simple-label`, children: label });
  return /* @__PURE__ */ jsx(
    HeadTitleWrapper,
    {
      as,
      className: clsx(`mec-head-title`, className, {
        "mec-head-title--thin": thin,
        "mec-head-title--broken": breakWords,
        "mec-head-title--primary": variant === "primary",
        "mec-head-title--secondary": variant === "secondary"
      }),
      children: breakWords ? /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx("span", { className: `mec-head-title__first-label`, children: first }),
        second && (!alignLabels ? /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx("span", { className: `mec-head-title__second-label`, children: second }) }) : /* @__PURE__ */ jsx("span", { className: `mec-head-title__second-label aligned`, children: second }))
      ] }) : href ? /* @__PURE__ */ jsx(Link, { to: href, external: false, customLink, children: LabelComponent }) : LabelComponent
    }
  );
};
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const breakWordInTwo = (words) => {
  const splitted = words.trim().split(" ");
  const firstSplitLength = Math.ceil(splitted.length / 2);
  const first = splitted.slice(0, firstSplitLength).join(" ");
  const second = capitalizeFirstLetter(
    splitted.slice(firstSplitLength).join(" ")
  );
  return [first, second];
};
const HeadTitleWrapper = ({
  as = "h1",
  children,
  className = ""
}) => {
  const elementFC = {
    div: (props) => /* @__PURE__ */ jsx("div", { ...props }),
    h1: (props) => /* @__PURE__ */ jsx("h1", { ...props }),
    h2: (props) => /* @__PURE__ */ jsx("h2", { ...props }),
    h3: (props) => /* @__PURE__ */ jsx("h3", { ...props }),
    h4: (props) => /* @__PURE__ */ jsx("h4", { ...props }),
    h5: (props) => /* @__PURE__ */ jsx("h5", { ...props }),
    h6: (props) => /* @__PURE__ */ jsx("h6", { ...props }),
    li: (props) => /* @__PURE__ */ jsx("li", { ...props })
  };
  const HeadValue = elementFC[as];
  return /* @__PURE__ */ jsx(HeadValue, { className, children });
};
export {
  HeadTitle,
  elementTypes
};
