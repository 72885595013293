import * as React from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { CalculatorSidebar } from "../../components/calculator-sidebar";
import { Article, HeadTitle } from "@monemprunt/design-system";
import CtaImage from "../../components/cta-image";
import { Link as LinkComponent } from "gatsby";
import { formatDate, jsonFetch } from "../../lib/functions";
import Icon from "../../components/icon";
import {
  Chart,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";

export interface CalculatriceMeteoProps {
  SEO: {
    description: string;
    title: string;
  };
  calculatorPage: {
    article: string;
    title: string;
  };
  oat_title: string;
  table_title: string;
  oat_text: string;
  table_text: string;
}

export default function CalculatriceMeteo({
  data: {
    page: {
      SEO,
      calculatorPage: { article, title },
      oat_title,
      table_title,
      oat_text,
      table_text,
    },
  },
  location: { pathname: path },
}: PageProps<{ page: CalculatriceMeteoProps }>) {
  const [isSidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <Layout active={`simulation-emprunt-immobilier`}>
      <Seo {...SEO} pathname={path} />
      <main className="mt-25 sm:mt-32 md:mt-20 mb-10">
        <div
          className="hidden lg:block fixed top-72 h-60 2xl:top-80 2xl:h-80 left-0 right-0"
          style={{ zIndex: -1 }}
        >
          <div className="bg-gradient-to-t from-light-50 to-transparent h-full" />
          <div className="bg-gradient-to-b from-light-50 to-transparent h-full" />
        </div>

        <div className={"grid grid-cols-10 xl:grid-cols-12"}>
          {/* SideBar */}
          <CalculatorSidebar
            active={"meteo"}
            isOpen={isSidebarOpen}
            onToggle={setSidebarOpen}
          />

          {/* Content */}
          <div
            className={`col-span-8 px-5 pt-10 flex-col gap-5 mb-32 
             ${isSidebarOpen ? `hidden` : `flex`} lg:flex
              md:pt-15
              lg:pt-20
              lg:col-span-7
              xl:col-span-9
              lg:px-15`}
          >
            <HeadTitle
              as="h1"
              label={title}
              breakWords={false}
              className={`lg:text-2xl font-bold uppercase italic`}
            />

            <section className="my-6">
              <h2 className="text-xl uppercase font-bold my-2 text-secondary-100">
                {table_title}
              </h2>

              <Article
                content={table_text}
                customLink={props => <LinkComponent {...props} />}
                callToAction={() => <CtaImage />}
              />

              <MeteoTable />
            </section>

            <section className="my-6">
              <h2 className="text-xl uppercase font-bold my-2 text-secondary-100">
                {oat_title}
              </h2>

              <Article
                content={oat_text}
                customLink={props => <LinkComponent {...props} />}
                callToAction={() => <CtaImage />}
              />

              <OATGraph />
            </section>
            <Article
              content={article}
              customLink={props => <LinkComponent {...props} />}
              callToAction={() => <CtaImage />}
            />
          </div>
        </div>
      </main>
    </Layout>
  );
}

type MeteoApiResult = {
  d_maj: string;
  taux: Array<{
    id: number;
    meteo_id: number;
    meilleur: number;
    duree: number;
    moyen: number;
    tendance: 0 | 1 | -1;
  }>;
};

function MeteoTable() {
  const [
    meteoApiResponse,
    setMeteoApiResponse,
  ] = React.useState<MeteoApiResult | null>(null);
  React.useEffect(() => {
    const controller = new AbortController();
    jsonFetch<MeteoApiResult>(
      `/app/me2/meteo/ditPourRegion`,
      {},
      controller.signal
    ).then(res => {
      setMeteoApiResponse(res);
    });
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <div className="w-full overflow-x-auto">
        <table className="border border-dark-100 bg-light-50 w-full text-left text-xs md:text-base">
          <thead className="bg-dark-100 text-white">
            <tr>
              <th className="border-r border-white px-6 py-3">Taux fixe*</th>
              {!meteoApiResponse ? (
                <>
                  <th className="px-6 py-3 border-r border-l border-white text-center">
                    - ans
                  </th>
                  <th className="px-6 py-3 border-r border-l border-white text-center">
                    - ans
                  </th>
                  <th className="px-6 py-3 border-r border-l border-white text-center">
                    - ans
                  </th>
                  <th className="px-6 py-3 border-r border-l border-white text-center">
                    - ans
                  </th>
                </>
              ) : (
                meteoApiResponse.taux.map(({ id, duree }) => (
                  <th
                    key={`th-${id}`}
                    className="px-6 py-3 border-r border-l border-white text-center"
                  >
                    {duree} ans
                  </th>
                ))
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-dark-100 px-6 py-3">
                Meilleur taux constaté
              </td>
              {!meteoApiResponse ? (
                <>
                  <td className="border border-dark-100 px-6 py-3 text-center">
                    - %
                  </td>
                  <td className="border border-dark-100 px-6 py-3 text-center">
                    - %
                  </td>
                  <td className="border border-dark-100 px-6 py-3 text-center">
                    - %
                  </td>
                  <td className="border border-dark-100 px-6 py-3 text-center">
                    - %
                  </td>
                </>
              ) : (
                meteoApiResponse.taux.map(({ id, meilleur }) => (
                  <td
                    key={`td-meilleur-${id}`}
                    className="border border-dark-100 px-6 py-3 text-center"
                  >
                    {Intl.NumberFormat("fr-FR", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 0,
                    }).format(meilleur / 100)}
                    &nbsp;%
                  </td>
                ))
              )}
            </tr>
            <tr>
              <td className="border border-dark-100 px-6 py-3">
                Taux moyen constaté
              </td>
              {!meteoApiResponse ? (
                <>
                  <td className="border border-dark-100 px-6 py-3 text-center">
                    - %
                  </td>
                  <td className="border border-dark-100 px-6 py-3 text-center">
                    - %
                  </td>
                  <td className="border border-dark-100 px-6 py-3 text-center">
                    - %
                  </td>
                  <td className="border border-dark-100 px-6 py-3 text-center">
                    - %
                  </td>
                </>
              ) : (
                meteoApiResponse.taux.map(({ id, moyen, tendance }) => (
                  <td
                    key={`td-moyen-${id}`}
                    className="border border-dark-100 px-6 py-3 text-center"
                  >
                    <div className="inline-flex items-center gap-1">
                      <span>
                        {Intl.NumberFormat("fr-FR", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 0,
                        }).format(moyen / 100)}
                        &nbsp;%
                      </span>
                      <span>
                        {tendance === 1 ? (
                          <Icon
                            icon="arrow-up-long"
                            strokeWidth={4}
                            className="text-red-500 h-4 w-4"
                          />
                        ) : tendance == -1 ? (
                          <Icon
                            strokeWidth={4}
                            icon="arrow-down-long"
                            className="text-green-500 h-4 w-4"
                          />
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </td>
                ))
              )}
            </tr>
          </tbody>
        </table>
      </div>

      <small className="self-end">
        *Taux mis à jour le :&nbsp;
        {meteoApiResponse !== null
          ? formatDate({ date: meteoApiResponse.d_maj })
          : "-"}
      </small>
    </div>
  );
}

type OATApiResult = Array<{ x: string; y: number }>;

function OATGraph() {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [oatApiResult, setOATAPIResult] = React.useState<OATApiResult>(null);

  const chartRef = React.useRef<Chart>(null);

  const drawChart = React.useCallback((data: OATApiResult) => {
    if (canvasRef.current) {
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      const { matches: isMobile } = window.matchMedia("(max-width: 640px)");
      const ctx = canvasRef.current.getContext("2d");
      const labels = data.map(({ x }) =>
        isMobile
          ? formatDate({ date: x, day: false, month: false })
          : formatDate({ date: x, day: false, month: "short" })
      );
      const dataset = data.map(({ y }) => y);

      chartRef.current = new Chart(ctx, {
        type: "line",
        options: {
          responsive: true,
          aspectRatio: 16 / 9,
        },
        data: {
          labels,
          datasets: [
            {
              label: "Evolution de l'OAT",
              data: dataset,
              fill: false,
              borderColor: "#ffcd56",
              tension: 0.1,
              borderWidth: 2,
              pointBorderWidth: 1,
              pointRadius: 1,
            },
          ],
        },
      });
    }
  }, []);

  React.useEffect(() => {
    const listener = () => {
      drawChart(oatApiResult);
    };

    if (oatApiResult) {
      listener();
      /**
       * Ne redessiner le graphe que lorsque la taille de la fenêtre est modifiée
       */
      window.addEventListener("resize", listener);
    }
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [oatApiResult]);

  React.useEffect(() => {
    Chart.register(
      LineController,
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement
    );
    const controller = new AbortController();

    jsonFetch<OATApiResult>(
      `/app/me2/indice/listeCoursGraph?indice=OAT10&debut=2000-01-01&_=1676647046849`,
      {},
      controller.signal
    ).then(data => {
      setOATAPIResult(data);
    });

    return () => {
      chartRef.current?.destroy();
      controller.abort();
      Chart.unregister(
        LineController,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement
      );
    };
  }, []);

  return (
    <div className={`relative w-full flex items-center`}>
      <canvas ref={canvasRef} className={`w-full bg-transparent`} />
    </div>
  );
}

export const query = graphql`
  query Meteo {
    page: strapiPageMeteoDesTaux {
      oat_title
      oat_text
      table_title
      table_text
      SEO {
        description
        title
      }
      calculatorPage {
        article
        title
      }
      calculatorSidebar {
        title
        icon {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
      strapiId
    }
  }
`;
